import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ConfirmationModalContextProvider } from './components/Util/ConfirmationModalContextProvider';
import { SelectPrinterDialogContextProvider } from './components/Util/SelectPrinterDialogContext';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const AppComponent = React.lazy(() => import('./App'));

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <SnackbarProvider maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ConfirmationModalContextProvider>
        <SelectPrinterDialogContextProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <AppComponent />
          </Suspense>
        </SelectPrinterDialogContextProvider>
      </ConfirmationModalContextProvider>
    </SnackbarProvider>
  </BrowserRouter >,
  rootElement);
