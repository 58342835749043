import React, { useState, useRef, useContext } from 'react';
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { axiosError } from '.';
import { Box } from '@mui/system';


const ConfirmationModalContext = React.createContext({
    showConfirmation: (title, message) => { },
    showConfirmYesNo: (title, message) => { },
    showErrorMessage: (ex, title) => { },
    showMessage: (title, message) => { }
});

export const ConfirmationModalContextProvider = (props) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [data, setData] = useState({});
    const resolver = useRef();

    const handleShow = (title, message) => {
        setData({ title, message, noBtn: 'Ok' });
        setShowConfirmationModal(true);

        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    };

    const confirm = (title, message) => {
        setData({ title, message, yesBtn: 'Yes', noBtn: 'No' });
        setShowConfirmationModal(true);
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    }

    const errorMessage = (ex, title) => {
        setData({ title, error: axiosError.errorDescription(ex), yesBtn: '', noBtn: 'Ok' });
        setShowConfirmationModal(true);
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    }

    const message = (title, message) => {
        setData({ title, message, yesBtn: '', noBtn: 'Ok' });
        setShowConfirmationModal(true);
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    }

    const handleOk = () => {
        resolver.current && resolver.current(true);
        setShowConfirmationModal(false)
    };

    const handleCancel = () => {
        resolver.current && resolver.current(false);
        setShowConfirmationModal(false)
    };

    return (
        <ConfirmationModalContext.Provider value={{ showConfirmation: handleShow, 
                                                    showConfirmYesNo: confirm, 
                                                    showErrorMessage: errorMessage ,
                                                    showMessage: message}}>
            {props.children}

            <Dialog open={showConfirmationModal} onClose={handleCancel}>
                {data.error &&
                    <Box m={1}>
                        <Alert severity='error' variant="filled" width='100%'>
                            <AlertTitle>{data.title ?? "Error"}</AlertTitle>
                            <Typography>{data.error}</Typography>
                        </Alert>
                    </Box>}

                {!data.error && <>
                    <DialogTitle>{data.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{data.message}</DialogContentText>
                    </DialogContent> </>}
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary" variant='contained'>{data.noBtn}</Button>
                    {data.yesBtn && <Button onClick={handleOk} color="primary" variant='contained'>{data.yesBtn}</Button>}
                </DialogActions>
            </Dialog>

        </ConfirmationModalContext.Provider>
    )
};


export const useConfirmationModalContext = () => useContext(ConfirmationModalContext);
