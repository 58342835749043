import React, { useState, useContext, useRef, useEffect } from 'react';
import { AppBar, Backdrop, CircularProgress, Dialog, FormControlLabel, IconButton, Stack, Switch, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import { List } from 'devextreme-react/list';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useConfirmationModalContext } from './ConfirmationModalContextProvider';

const SelectPrinterDialogContext = React.createContext({
    printDevexReport: (title, name, parameters, path) => { },
    printZoneLable: (parameters) => { },
    printBinLable: (parameters) => { },
    printToteLable: (parameters) => { },
    printShippingBoxLabel: (WmsPickListOrderId, BoxNumber) => { },
    printInvoice: (invoiceId) => { }
});

export const useSelectPrinterDialogContext = () => useContext(SelectPrinterDialogContext);


export const SelectPrinterDialogContextProvider = (props) => {
    const [showSelectPrinterModal, setShowSelectPrinterModal] = useState(false);
    const [webPrinters, setWebPrinters] = useState();
    const [saveDefaultPrinter, setSaveDefaultPrinter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const resolver = useRef();
    const modalContext = useConfirmationModalContext();

    const printToDefaultOrLoadPrinters = async () => {
        const savedDefailtPrinterJson = localStorage.getItem("default_printer_" + data.name);
        if (savedDefailtPrinterJson) {
            const defaultPrinter = JSON.parse(savedDefailtPrinterJson);
            if (await print(defaultPrinter.workStation, defaultPrinter.printerName)) {
                return;
            }
        }
        loadWebPrinters();
    }

    const loadWebPrinters = async () => {
        try {
            setIsLoading(true);
            const results = await axios.get('api/print/WebPrinters')
            setWebPrinters(results.data);
            setIsLoading(false);
        } catch (ex) {
            modalContext.showErrorMessage(ex);
        } finally {
            setIsLoading(false);
        }
    };

    function PrinterInfo(item) {
        return (
            <div className="product">
                <div>{item.printerName}</div>
            </div>
        );
    }

    function GroupTemplate(item) {
        return <div>Print WorkStation: {item.key}</div>;
    }


    const onSelectionChanged = async (e) => {
        const selectedPrinter = e.addedItems[0];
        await print(selectedPrinter.workStation, selectedPrinter.printerName);
    };

    const print = async (workStation, printerName) => {
        try {
            setIsLoading(true);
            if (Array.isArray(data.parameters)) {
                for (const param of data.parameters) {
                    await printPayload(workStation, printerName, param.toString());
                }
            } else {
                await printPayload(workStation, printerName, data.parameters.toString());
            }

            if (saveDefaultPrinter) {
                localStorage.setItem("default_printer_" + data.name, JSON.stringify({ workStation, printerName }));
            }

            resolver.current && resolver.current(true);
            setShowSelectPrinterModal(false)
        } catch (ex) {
            modalContext.showErrorMessage(ex);
            return false;
        } finally {
            setIsLoading(false);
        }
        return true;
    }

    const printPayload = async (workStation, printerName, parameter) => {
        const payload = {
            workStation,
            printer: printerName,
            parameters: parameter,
            path: data.path,
            type: data.type
        };
        const results = await axios.post('api/print/print', payload);
    }

    const handleClose = () => {
        resolver.current && resolver.current(false);
        setShowSelectPrinterModal(false)
    };

    const handlePrintDevexReport = (title, name, parameters, path) => {
        setData({ title, name, parameters, path, type: 'XtraReport' });
        setShowSelectPrinterModal(true);
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    }

    const handlePrintCrystalReport = (title, name, parameters, path) => {
        setData({ title, name, parameters, path, type: 'Crystal' });
        setShowSelectPrinterModal(true);
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    }

    useEffect(() => {
        if (!data || !showSelectPrinterModal) return;
        setShowSelectPrinterModal(true);
        printToDefaultOrLoadPrinters();
    }, [data, showSelectPrinterModal])

    const handlePrintZoneLable = (parameters) => handlePrintDevexReport('Print Zone Labels', 'print_zone_label', parameters, 'ZoneLable');
    const handlePrintBinLable = (parameters) => handlePrintDevexReport('Print Bin Labels', 'print_bin_label', parameters, 'BinLable');
    const handlePrintToteLable = (parameters) => handlePrintDevexReport('Print Tote Labels', 'print_tote_label', parameters, 'ToteLable');
    const handlePrintInvoice = (invoiceId) => handlePrintCrystalReport('Print Invoice', 'print_invoice', JSON.stringify({ "@InvoiceID": invoiceId }), 'Invoice.rpt');
    const handlePrintShippingBoxLabel = (WmsPickListOrderId, BoxNumber) => {
        const params = JSON.stringify({ WmsPickListOrderId, BoxNumber });
        handlePrintDevexReport('Print Shipping Box Label', 'print_shipping_box_label', params, 'ShippingBoxLabel');
    }

    return (
        <SelectPrinterDialogContext.Provider value={{
            printDevexReport: handlePrintDevexReport,
            printZoneLable: handlePrintZoneLable,
            printBinLable: handlePrintBinLable,
            printToteLable: handlePrintToteLable,
            printShippingBoxLabel: handlePrintShippingBoxLabel,
            printInvoice: handlePrintInvoice
        }}>
            {props.children}

            <Dialog
                fullScreen
                open={showSelectPrinterModal}
                onClose={handleClose}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.Dialog + 10000 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Select a printer
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box m={1}>
                    <Paper elevation={2}>
                        <Stack m={1} p={1} direction="row" spacing={1} alignItems="center">
                            <Typography variant="h6" gutterBottom component="div">{data.title}</Typography>
                            <FormControlLabel control={<Switch checked={saveDefaultPrinter} onChange={(e) => setSaveDefaultPrinter(e.target.checked)} />} label='Save Default Printer' />
                        </Stack>
                    </Paper>
                </Box>
                <Box m={1}>
                    <List
                        dataSource={webPrinters}
                        onSelectionChanged={onSelectionChanged}
                        keyExpr='id'
                        height='100%'
                        scrollingEnabled
                        selectionMode="single"
                        itemRender={PrinterInfo}
                        grouped={true}
                        group
                        collapsibleGroups={true}
                        groupRender={GroupTemplate}
                        pageLoadMode="scrollBottom"
                        searchExpr="printerName"
                        searchEnabled
                    />
                </Box>
            </Dialog>


        </SelectPrinterDialogContext.Provider>
    )
}